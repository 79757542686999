import { graphql, Link } from 'gatsby';
import React, { Fragment, ReactNode } from 'react';
import BaseComponent from '../../../base/base-component';
import { Route } from '../../../global/route-enum';
import { IEmbeddedOverallMapProperties } from './overall-map-interface';
import ContainerBox from '../../../components/container-box/container-box';
import ShareContainer from '../../../components/share-container/share-container';
import SocialMetadata from '../../../components/social-metadata/social-metadata';
import { SocialMetadataType } from '../../../components/social-metadata/social-metadata-enum';
import SvgIcon from '../../../components/svg-icon/svg-icon';
import { SvgIconPathCommand } from '../../../components/svg-icon/svg-icon-enum';
import { SODMeasures } from '../../../global/global-enum';
import SODMapChart from '../../../components/charts/map/sod/sod-map-chart';

export default class EmbeddedOverallMap extends BaseComponent<IEmbeddedOverallMapProperties> {
  protected declareTranslateCollection(): string {
    return 'overallMapTemplate';
  }

  public render(): ReactNode {
    const { data } = this.props;

    return (
      <Fragment>
        <SocialMetadata
          type={SocialMetadataType.SUMMARY}
          title={this.translate('title')}
          description={this.translate('description')}
          imageResourceFileName="embedded-overall-map.png"
        />
        <ContainerBox className="c-section--embed">
          <ShareContainer className="c-embed__wrap" shareName="overall-map">
            <div className="c-embed__box">
              <h2 className="c-score__title">{this.translate('title')}</h2>
              <SODMapChart data={data} measure={SODMeasures.GRAND_TOTAL} />
            </div>
            <div className="c-embed__logo">
              <Link to={Route.HOME}>
                <SvgIcon
                  width="111"
                  viewBox="0 0 208 44"
                  paths={[
                    {
                      command: SvgIconPathCommand.STATE_OF_DEMOCRACY,
                      fill: '#0D0C0D',
                    },
                    {
                      fillRule: 'evenodd',
                      clipRule: 'evenodd',
                      command: SvgIconPathCommand.LOGO_EAST,
                      fill: '#C7AFF0',
                    },
                    {
                      fillRule: 'evenodd',
                      clipRule: 'evenodd',
                      command: SvgIconPathCommand.LOGO_WEST,
                      fill: '#4A1D96',
                    },
                  ]}
                />
              </Link>
            </div>
          </ShareContainer>
        </ContainerBox>
      </Fragment>
    );
  }
}

export const query = graphql`
  query GetEmbeddedMapOverallData {
    all: allSheetDataGrandTotal {
      nodes {
        code
        state
        grade
      }
    }
  }
`;
